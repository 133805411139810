import Learnmorelink from '../Learnmorelink/Learnmorelink';
import React, { useState } from 'react';
import './Aireponsebox.css';
import useAiResponseSources from '../../hooks/chat-room/useAiResponseSources';
import useSuggestedPrompt from '../../hooks/chat-room/useSuggestedPrompt';
import {
  copyTextToClipBoard,
  getHostFromUrl,
  removeHtmlTags,
  textTruncate,
} from '../../utils/shared';
import { toast } from 'react-hot-toast';
import AIResponseRating from '../../modules/chat-room/upper-area/response-rating';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';
import { useDebounce } from '../../hooks/useDebounce';
import { useSelector } from 'react-redux';
import Markdown from '../../modules/markdown/Markdown';
import { useTranslation } from 'react-i18next';

const Aireponsebox = (props) => {
  const { sources } = useAiResponseSources(
    props.userQuery,
    props.streaming,
    props.message
  );
  const chat = useSelector((state) => state.chat);
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const { t } = useTranslation();

  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const anonymous = useAnonymousMode();
  const [copiedTriggered, setCopiedTriggered] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState(false);
  const [showShareLayout, setShowShareLayout] = useState(false);

  const parseSearchResults = () => {
    return props.message?.searchResults?.map((record) => {
      return {
        url: record.source,
        status: 'show',
        displayName:
          record?.type?.toLowerCase() === 'url'
            ? getHostFromUrl(record.source)
            : textTruncate(record.source, 10),
      };
    });
  };

  useDebounce(
    () => {
      if (!props.streaming) {
        setShowShareLayout(true);
      }
    },
    200,
    [props.streaming]
  );

  return (
    <div className="ai-response-container ai-prompt-response-container light-black-bg-dark-mode learn-more-container">
      <div className="w-layout-hflex ai-response-container-content">
        <div className="ai-gif-container">
          <img
            alt=""
            className="image-3"
            loading="lazy"
            style={{ borderRadius: '50%' }}
            src={
              mentors?.mentor?.settings?.profile_image ??
              '/images/ai-academy-p-500.png'
            }
          />
        </div>
        <div
          className="ai-response-text-container"
          id={`ai-response-text-container-${props.index}`}
        >
          <Markdown>{props?.message?.rawContent}</Markdown>
        </div>
      </div>
      {showShareLayout && !props?.message?.share && (
        <div className="w-layout-vflex learn-more-block">
          {sources?.length > 0 ||
            (props.message?.searchResults && (
              <div className="w-layout-hflex learn-more-container all-whiten-in-dark-mode">
                <h5 className="heading-10">
                  {t('Learn more')}
                  {' :'}
                </h5>
                {sources?.map((item) => (
                  <Learnmorelink key={item.url} source={item} />
                ))}

                {parseSearchResults()?.map((item) => (
                  <Learnmorelink key={item.url} source={item} />
                ))}
              </div>
            ))}

          <div className="w-layout-hflex rating-copy-retry-block">
            {auth?.user?.user_nicename && (
              <AIResponseRating userMsg={props.userQuery} msg={props.message} />
            )}
            <div className="w-layout-hflex copy-retry-container all-whiten-in-dark-mode">
              <a
                className="response-action-link copy-action-btn greyish-bg-in-darkmode w-inline-block"
                href="#"
                onClick={
                  !copiedTriggered
                    ? () => {
                        copyTextToClipBoard(
                          document.getElementById(
                            `ai-response-text-container-${props.index}`
                          ).innerText
                        );
                        toast.success('Copied', {
                          autoClose: 3000,
                        });
                        setCopiedTriggered(true);
                        setTimeout(() => {
                          setCopiedTriggered(false);
                        }, 3000);
                      }
                    : () => {}
                }
                prop-events-value-onclick="handleCopyResponseBtnClick"
              >
                <img
                  alt=""
                  className="response-action-icon"
                  loading="lazy"
                  src={`/images/${!copiedTriggered ? 'clipboard.svg' : 'check.svg'} `}
                />
                <div className="response-action-label">
                  {!copiedTriggered ? 'Copy' : 'Copied'}
                </div>
              </a>
              <a
                className="response-action-link retry-action-btn greyish-bg-in-darkmode w-inline-block"
                href="#"
                onClick={() => {
                  const shareUrl = `${window.location.origin}/share/chat/${chat.sessionId}`;
                  copyTextToClipBoard(shareUrl);
                  setCopiedUrl(true);
                  setTimeout(() => {
                    setCopiedUrl(false);
                  }, 3000);
                }}
                prop-events-value-onclick="handleCopyShareLinkBtnClick"
              >
                <img
                  alt=""
                  className="response-action-icon"
                  loading="lazy"
                  src="/images/66745f942d78b122e199d7ea_link.svg"
                />
                <div className="response-action-label">
                  {copiedUrl ? 'Copied' : 'Share'}
                </div>
              </a>
              <a
                className="response-action-link retry-action-btn greyish-bg-in-darkmode w-inline-block"
                href="#"
                onClick={() => {
                  handleSuggestedPromptSelection(
                    props.userQuery?.rawContent || props.userQuery?.content
                  );
                }}
                prop-events-value-onclick="handleRetryResponseBtnClick"
              >
                <img
                  alt=""
                  className="response-action-icon"
                  loading="lazy"
                  src="/images/rotate-ccw.svg"
                />
                <div className="response-action-label">{t('Retry')}</div>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Aireponsebox;
